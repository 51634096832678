@media print {
  .print-grid {
    gap: 8px;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .print-card {
    page-break-inside: avoid;
    break-inside: avoid;
    font-size: 12px;
  }
  .print-hide {
    display: none !important;
  }
  .print-card-body {
    padding: 10px !important;
  }
  .print-card-header {
    padding: 8px !important;
  }
  .print-heading {
    font-size: 13px;
  }
  .print-watermark {
    display: block !important;
    position: absolute;
    top: 3%;
    left: 68%;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 9999;
  }
}
